import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import '../../MainStyle.css';
import general from '../css/General.module.css';
import LoadingDialog from '../dialogs/LoadingDialog/dialog';

import { getStorage } from '../modals/Storage';

const JiliGamesController = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const loginUrl = new URLSearchParams(location.search).get('loginUrl');
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        if (!getStorage("uid")) {
            navigate('/login', { replace: true });
        }
    }, [navigate]);

    const handleCloseIframe = () => {
        navigate('/all3rdPartyGames');
    };

    return (
        <div className={[general.viewCenter, general.appBackground].join(" ")} style={{ height: '100vh', margin: 0, padding: 0 }}>
            <LoadingDialog intentData={showLoading} />

            <div
                className={[
                    general.height100vh,
                    general.respWidth,
                    general.overflowHidden, // Prevent scrolling within the container
                    general.appContentsBackground,
                ].join(" ")}
                style={{ position: "relative", overflow: "hidden", height: "100vh", width: "100%" }}
            >
                <iframe
                    src={loginUrl}
                    style={{
                        width: "100%",
                        height: "100%",
                        border: "none",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        zIndex: 1,
                    }}
                    title="Jili Games"
                />
            </div>
        </div>
    );
}

export default JiliGamesController;
