import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TopBar from "../other-components/TopBar";

import LoadingDialog from "../dialogs/LoadingDialog/dialog";
import "../../MainStyle.css";
import { API_ACCESS_URL } from "../modals/Constants";
import { getStorage } from "../modals/Storage";

import styles from "../css/Screens.module.css";
import general from "../css/General.module.css";

function AllBankCards() {
  const navigate = useNavigate();
  const listViewRef = useRef();
  const [showLoading, setShowLoading] = useState(false);

  const [pageConst, setConstants] = useState({
    pageTitle: "Withdraw Records",
    pageCount: 1,
    recordList: [],
  });

  const topBarClickAction = (data) => {
    if (data == "multiBtn2") {
      navigate("/addbankcard", { replace: false });
    }
  };

  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  };

  const onScroll = () => {
    if (listViewRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listViewRef.current;
      const isNearBottom = scrollTop + clientHeight >= scrollHeight;

      if (isNearBottom) {
        let newPageVal = pageConst.pageCount + 1;
        setConstants((previousState) => {
          return { ...previousState, pageCount: newPageVal };
        });
      }
    }
  };

  const updateRecordList = (data) => {
    let tempData = [];

    for (let i = 0; i < data.length; i++) {
      tempData.push(
        <div key={i} className="pr-v w-100 br-5 mg-t-15 bg-extm-l-white">
          <div
            className={`ps-ab ps-tp ps-lf pd-2-8 br-5 ft-sz-15 cl-white ${
              data[i]["w_status"] == "success"
                ? "bg-green"
                : data[i]["w_status"] == "rejected"
                ? "bg-red"
                : data[i]["w_status"] == "approve"
                ? "bg-l-black"
                : "bg-l-black"
            }`}
          >
            {data[i]["w_status"] == "success"
              ? "completed"
              : data[i]["w_status"] == "rejected"
              ? "cancelled"
              : data[i]["w_status"] == "approve"
              ? "approved"
              : "processing"}
          </div>
          <div className="ps-ab ps-tp ps-rgt pd-2-8 ft-sz-15">
            {data[i]["w_date"] + " " + data[i]["w_time"]}
          </div>

          <div className="col-view pd-5-15">
            <div className="pr-v col-view w-100 ft-sz-14 mg-t-20">
              <span className="ft-sz-15 mg-t-5">
                OrderId: {data[i]["w_uniq_id"]}
              </span>
              <span className="ft-sz-15 mg-t-5 mg-b-10">
                UPI: <span className="cl-blue"></span>
              </span>

              <div className="ps-ab ps-btm ps-rgt pd-2-8 mg-t-25 ft-sz-25">
                ₹{data[i]["w_request"]}
              </div>
            </div>

            <div className="row-view sb-view ft-sz-14 mg-t-15">
              <span>Wallet:</span>
              <span>
                Bank:{" "}
                <span className="cl-blue">{data[i]["w_bank_account"]}</span>
              </span>
            </div>

            <div className="row-view sb-view ft-sz-14 mg-t-5">
              <span>
                Primary: <span className="cl-blue">IMPS</span>
              </span>
              <span>
                IFSC: <span className="cl-blue">{data[i]["w_bank_ifsc"]}</span>
              </span>
            </div>

            <div className="line-hv-grey mg-t-5"></div>
            <span className="ft-sz-14 mg-t-5">
              {data[i]["w_beneficiary"]} | Bank: {data[i]["w_amount"]} | Fees:{" "}
              {Number(data[i]["w_request"]) - Number(data[i]["w_amount"])}
            </span>
          </div>
          <div
  className={`rejection-msg ${data[i]["w_extradetails"] ? 'visible' : 'hidden'}`}
>
  {data[i]["w_extradetails"]}
</div>

        </div>
      );
    }

    setConstants((previousState) => {
      return { ...previousState, recordList: tempData };
    });
  };

  function getWithdawRecords() {
    const fecthApiData = async (url) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            route: "route-withdraw-records",
            AuthToken: getStorage("secret"),
          },
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if ((data.status_code = "success")) {
          updateRecordList(data.data);
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    updateLoadingStatus(true);
    fecthApiData(
      API_ACCESS_URL +
        "?USER_ID=" +
        getStorage("uid") +
        "&PAGE_NUM=" +
        pageConst.pageCount
    );
  }

  useEffect(() => {
    getWithdawRecords(pageConst.pageCount);
    const listInnerElement = listViewRef.current;

    if (listInnerElement) {
      listInnerElement.addEventListener("scroll", onScroll);

      // Clean-up
      return () => {
        listInnerElement.removeEventListener("scroll", onScroll);
      };
    }
  }, []);

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <TopBar
          intentData={pageConst}
          multiBtn={true}
          multiBtn1=""
          multiBtn2=""
          updateState={topBarClickAction}
        />
        <LoadingDialog intentData={showLoading} />

        <div className={[general.colView, general.mgT50px].join(" ")}>
          <div className="col-view br-right-t br-left-t mg-b-15">
            <div className="col-view pd-5-15">{pageConst.recordList}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllBankCards;
