import React, { useEffect, useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import TopBar from '../other-components/TopBar';
import ToastDialog from '../dialogs/ToastDialog/toast';
import '../../MainStyle.css';
import { WEBSITE_NAME,redirectTo } from '../modals/Constants';
import { getStorage } from '../modals/Storage';
import ShareOptionsView from '../other-components/ShareOptionsView';

function Privilege(){
  const navigate = useNavigate();
  
    const [pageConst, setConstants] = useState({
        pageTitle: "Privilege",
        isLoadingShow: false,
        toastDialogShow: false,
        toastMessage: "",
        recordList: [],
    });

    const topBarClickAction = (data) =>{
    }

    
    const updateToastDialogState = (data,msg) => {
        setConstants(previousState => {
          return { ...previousState, toastDialogShow: data }
        });
  
        setConstants(previousState => {
          return { ...previousState, toastMessage: msg }
        });
    }

    useEffect(() => {
        if(!getStorage("uid")){
          navigate('/login', { replace: true });
        }
      }, []);

    return (
      <div className="v-center bg-black">
        <div className="h-100vh res-wth bg-black">
          <ToastDialog intentData={pageConst} updateState={updateToastDialogState} />

          <div className='pr-v ovf-scrl-y hide-sb zoomAnimView'>
            <TopBar intentData={pageConst} multiBtn={true} multiBtn1="" multiBtn2="" updateState={topBarClickAction}/>

            <div className="col-view mg-b-15">

              <div className='col-view v-center w-100 pd-5-15 mg-t-45'>

                  <div className={`col-view w-100 min-h`}>
                    <span className='ft-sz-20 mg-t-10 cl-white'>Invite Bonus</span>
                    <span className='ft-sz-15 mg-t-10 cl-white'>You will get the bonus when you refer a person and they complete first recharge.</span>
                    
                    <div className="row-view sb-view br-right-t br-left-t mg-t-20 pd-10-15 cl-white" style={{background: '#6F6F6F'}}>
                      <span>Deposit</span>
                      <span>Bonus</span>
                    </div>

                     <div className="col-view pd-10-15">

                      <div className="row-view mg-t-15 sb-view">
                        <span className='cl-white ft-sz-16 h-80-p br-50 v-center'>₹200</span>
                        <span className='cl-white ft-sz-16 h-80-p br-50 v-center'>₹38</span>
                      </div>

                      <div className="row-view mg-t-15 sb-view">
                        <span className='cl-white ft-sz-16 h-80-p br-50 v-center'>₹500</span>
                        <span className='cl-white ft-sz-16 h-80-p br-50 v-center'>₹68</span>
                      </div>

                      <div className="row-view mg-t-15 sb-view">
                        <span className='cl-white ft-sz-16 h-80-p br-50 v-center'>₹1000</span>
                        <span className='cl-white ft-sz-16 h-80-p br-50 v-center'>₹118</span>
                      </div>

                      <div className="row-view mg-t-15 sb-view">
                        <span className='cl-white ft-sz-16 h-80-p br-50 v-center'>₹3000</span>
                        <span className='cl-white ft-sz-16 h-80-p br-50 v-center'>₹228</span>
                      </div>

                      <div className="row-view mg-t-15 sb-view">
                        <span className='cl-white ft-sz-16 h-80-p br-50 v-center'>₹10000</span>
                        <span className='cl-white ft-sz-16 h-80-p br-50 v-center'>₹588</span>
                      </div>

                      <div className="row-view mg-t-15 sb-view">
                        <span className='cl-white ft-sz-16 h-80-p br-50 v-center'>₹50000</span>
                        <span className='cl-white ft-sz-16 h-80-p br-50 v-center'>₹2388</span>
                      </div>

                      <div className="row-view mg-t-15 sb-view">
                        <span className='cl-white ft-sz-16 h-80-p br-50 v-center'>₹200000</span>
                        <span className='cl-white ft-sz-16 h-80-p br-50 v-center'>₹10888</span>
                      </div>

                    </div>

                  </div>

              </div>

            </div>

          </div>
        </div>
      </div>
    );
}

export default Privilege;