import React from 'react';
import '../../MainStyle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';

const FirstDepositDailog = ({ intentData, updateState }) => {
  const handleTelegramClick = () => {
    window.location.href = 'https://telegram.dog/bigluck99_in';
  };

  return (
    <div
      className={`ps-fx h-100vh res-wth z-i--100 v-center bg-l-black ${
        intentData.noticeDialogShow ? 'activeDialog' : ''
      }`}
      onClick={() => updateState(false)}
    >
      <div className='tst-content-2'>
        <div>
          <div className='w-100 v-center ft-sz-20 cl-black br-t-rl-5 bg-white'>
            Bigluck99 Presents       
          </div>
          <div className='v-center ft-sz-13 pd-14 cl-black bg-white header-popup'>
            Each account can receive the rewards once!
          </div>
        </div>

        {/* Centered Text */}
        <div className="v-center ft-sz-16 cl-black text-center pd-5" style={{ textAlign: "center" }}>
          Recharge on any deposit channel, you will get bonus
        </div>

        {/* Bonus Image */}
        <div className="v-center pd-20">
          <img
            src="https://www.bonus.net.nz/images/first-deposit-bonuses.png"
            alt="First Deposit Bonuses"
            style={{ width: '100%', height: 'auto', borderRadius: '12px' }}
          />
        </div>

        {/* Sequence of Text */}
        <div className='pd-l-10 ft-sz-14 cl-black' style={{ marginLeft: "8px", fontWeight: "500"  }}>
          <div className="sequence-item" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px 0' }}>
            <span>
               First Deposit <span style={{ color: 'red' }}>₹200</span>
            </span>
            <span className="color-red" style={{ marginRight: '10px' }}>+₹200</span>
          </div>

          <div className="sequence-item" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px 0' }}>
            <span>
            First Deposit <span style={{ color: 'red' }}>₹500</span>
            </span>
            <span className="color-red" style={{ marginRight: '10px' }}>+₹500</span>
          </div>

          <div className="sequence-item" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px 0' }}>
            <span>
            First Deposit <span style={{ color: 'red' }}>₹1000</span>
            </span>
            <span className="color-red" style={{ marginRight: '10px' }}>+₹755</span>
          </div>

          <div className="sequence-item" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px 0' }}>
            <span>
            First Deposit <span style={{ color: 'red' }}>₹2000</span>
            </span>
            <span className="color-red" style={{ marginRight: '10px' }}>+₹1255</span>
          </div>

          <div className="sequence-item" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px 0' }}>
            <span>
            First Deposit <span style={{ color: 'red' }}>₹5000</span>
            </span>
            <span className="color-red" style={{ marginRight: '10px' }}>+₹2355</span>
          </div>
          <center>Requires <span style={{ color: 'red' }}>1x</span> Wager !!!</center>
        </div>

        <div>
          <div className='br-a-l-black'></div>

          <div className='pd-15'>
            <div
              className='cl-white br-20 bg-blue-grey telegram-button'
              onClick={handleTelegramClick}
              style={{ cursor: 'pointer' }}
            >
              <FontAwesomeIcon icon={faTelegram} size='1x' />
              <span className='ml-10'>Join Telegram</span>
            </div>
            <div className='w-100 v-center pd-5-10 ft-sz-18 cl-white br-20 bg-blue-grey'>
              Close
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstDepositDailog;
