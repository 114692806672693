import React, { useState } from "react";
import TopBar from "../other-components/TopBar";

import ToastDialog from "../dialogs/ToastDialog/toast";
import "../../MainStyle.css";
import { API_ACCESS_URL } from "../modals/Constants";
import { Link, useNavigate } from "react-router-dom";
import { getStorage, setStorage } from "../modals/Storage";
import styles from "../css/Screens.module.css";
import general from "../css/General.module.css";

function RedeemGiftcard() {
  const navigate = useNavigate();
  const [isInputValCorrect, setInValCorrect] = useState(false);
  const [pageConst, setConstants] = useState({
    pageTitle: "Redeem Giftcard",
    isLoadingShow: false,
    availBalance: 0,
    inRedeemCardId: "",
    withdrawlMode: "W",
    toastDialogShow: false,
    toastMessage: "",
    recordList: [],
  });

  const [toastDialogConst, setToastDialogConst] = useState({
    toastDialogShow: false,
    toastDialogMode: 1,
    toastMessage: "",
  });

  const updateToastDialogState = (data, mode, msg) => {
    // mode: 1 / success
    // mode: 2 / warning

    setToastDialogConst((previousState) => {
      return { ...previousState, toastDialogShow: data };
    });

    setToastDialogConst((previousState) => {
      return { ...previousState, toastDialogMode: mode };
    });

    setToastDialogConst((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };
  
  const topBarClickAction = (data) => {
    if (data == "multiBtn1") {
      navigate("/withdraw", { replace: false });
    } else if (data == "multiBtn2") {
      navigate("/withdrawrecords", { replace: false });
    }
  };

  const updateLoadingStatus = (data) => {
    setConstants((previousState) => {
      return { ...previousState, isLoadingShow: data };
    });
  };

  const onInputValChange = (source, data) => {
    if (source == "redeemcardid" && data.length >= 5) {
      setInValCorrect(true);
      setConstants((previousState) => {
        return { ...previousState, inRedeemCardId: data };
      });
    } else {
      setInValCorrect(false);
    }
  };

  const claimRedeemCard = () => {
    const fecthApiData = async (url, formData) => {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            route: "route-claim-giftcard",
            AuthToken: getStorage("secret"),
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if (data.status_code == "code_not_exist") {
          updateToastDialogState(true, 2, "Invalid Code!");
        } else if (data.status_code == "balance_limit") {
          updateToastDialogState(true, 2, "Insufficient Recharge Amount!");
        } else if (data.status_code == "already_applied") {
          updateToastDialogState(true, 2, "Already Claimed!");
        } else if (data.status_code == "success") {
          setStorage("balance", data.account_balance);
          updateToastDialogState(true, 1, "success");
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    if (isInputValCorrect && pageConst.isLoadingShow == false) {
      updateLoadingStatus(true);
      const formData = {
        USER_ID: getStorage("uid"),
        GIFTCARD_ID: pageConst.inRedeemCardId,
      };
      fecthApiData(API_ACCESS_URL, formData);
    }
  };

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
       <ToastDialog
          intentData={toastDialogConst}
          updateState={updateToastDialogState}
        />

        <div className="zoomAnimView">
          <TopBar
            intentData={pageConst}
            multiBtn={false}
            multiBtn1=""
            multiBtn2=""
            updateState={topBarClickAction}
          />

          <div className="col-view">
            <div
              className={[general.width100, general.bgCustomColor1].join(" ")}
            >
              <img
                className="w-70 mg-t-80"
                src={require("../icons/gift_box_icon.png")}
              />
            </div>

            <div className={[general.pd10px15px]}>
              <div
                className={[
                  general.pd10px15px,
                  general.borderRadius10px,
                  general.bgWhite,
                ].join(" ")}
              >
                <span
                  className={[general.fontS18Px, general.colorGrey].join(" ")}
                >
                  Please enter RedeemCard below
                </span>

                <input
                  type="text"
                  className={[
                    general.inputBox,
                    general.height50px,
                    general.mgT15px,
                    general.borderRadius10px,
                    general.bgLightGrey,
                  ].join(" ")}
                  placeholder="Please enter redeem code"
                  autoComplete="off"
                  onChange={(e) =>
                    onInputValChange("redeemcardid", e.target.value)
                  }
                ></input>

                <div
                  className={[
                    general.height45px,
                    general.width100,
                    general.viewCenter,
                    general.colorWhite,
                    general.fontS20Px,
                    general.mgT30px,
                    general.borderRadius,
                    general.bgCustomColor2,
                  ].join(" ")}
                  onClick={() => claimRedeemCard()}
                >
                  Claim
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RedeemGiftcard;
