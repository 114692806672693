import React, { useEffect, useState } from "react";
import BottomNavbar from "../other-components/BottomNavbar/Navbar";
import LoadingDialog from "../dialogs/LoadingDialog";
import "../../MainStyle.css";
import { Link, useNavigate } from "react-router-dom";
import { getStorage } from "../modals/Storage";
import { openNewPage } from "../modals/Constants";
import ToastDialog from "../dialogs/ToastDialog/toast";
import styles from "../css/Screens.module.css";
import general from "../css/General.module.css";
function Deposit() {
  const navigate = useNavigate();
  const [pageConst, setConstants] = useState({
    pageTitle: "Deposit",
    isLoadingShow: false,
    inRechargeAmnt: "",
    recordList: [],
  });

  const [toastDialogConst, setToastDialogConst] = useState({
    toastDialogShow: false,
    toastDialogMode: 1,
    toastMessage: "",
  });

  const updateToastDialogState = (data, mode, msg) => {
    // mode: 1 / success
    // mode: 2 / warning

    setToastDialogConst((previousState) => {
      return { ...previousState, toastDialogShow: data };
    });

    setToastDialogConst((previousState) => {
      return { ...previousState, toastDialogMode: mode };
    });

    setToastDialogConst((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };
  const [activeItem, setActiveItem] = useState('wepay');

// Add 6 gateway as max
  const items = [
    { id: 'wepay', type: 'upi5', name: 'Fast', img: require("../icons/payments/upi-icon.png") },
    { id: 'rspay', type: 'upi4', name: 'RsPay', img: require("../icons/payments/upi-icon.png") },
    { id: 'upiItem', type: 'upi', name: 'EkPay', img: require("../icons/payments/upi-icon.png") },
    { id: 'superpay', type: 'upi2', name: 'Super Pay', img: require("../icons/payments/upi-icon.png") },
  ];
  const handleOnClickChannel = (item) => {
    setActiveItem(item.id);
    console.log('Clicked item:', item);
    console.log('Item type:', item.type);
  };
  const topBarClickAction = (data) => {
    if (data == "multiBtn1") {
      navigate("/RechargeRecords", { replace: false });
    }
  };

  const onInputValChange = (source, data) => {
    console.log(data);

    if (source == "rechargeamount") {
      setConstants((previousState) => {
        return { ...previousState, inRechargeAmnt: data };
      });
    }
  };
  const [selectedItem, setSelectedItem] = useState(items[0].id); // Set the first item as default

  const rechargeNow = (item) => {
    const minRechargeAmount = Number(getStorage("minrecharge"));
    let RechargeAm = Number(pageConst.inRechargeAmnt);
    let UserId = getStorage("uid");
  
    // Check if the recharge amount is valid
    if (RechargeAm < minRechargeAmount) {
      // Show an error message for submitting below the minimum amount
      updateToastDialogState(true, 2, `Minimum recharge amount is ${minRechargeAmount}`);
      return; // Stop further execution if the amount is below the minimum
    }
  
    // Set active item if the recharge amount is valid
    setActiveItem(item.id);
  
    if (item.type === 'upi2') {
      // Proceed if the amount is valid for UPI2
      if (getStorage("paymentURL")) {
        openNewPage(
          "https://manual.bigluck99.in" +
            "?am=" +
            RechargeAm +
            "&crudentials=" +
            UserId
        );
        updateToastDialogState(true, 1, "Order Created Successfully!");
      }
      return;
    }
  
    let paymentUrl;
    switch (item.type) {
      case 'upi1':
        paymentUrl = getStorage("paymentURL") + "/payments/gateways1/initialisation";
        break;
      case 'upi3':
        paymentUrl = getStorage("paymentURL") + "/payments/gateways3/initialisation";
        break;
      case 'upi4':
        paymentUrl = getStorage("paymentURL") + "/payments/gateways4/initialisation";
        break;
      case 'upi5':
        paymentUrl = getStorage("paymentURL") + "/payments/gateways5/initialisation";
        break;
      case 'upi6':
        paymentUrl = getStorage("paymentURL") + "/payments/gateways6/initialisation";
        break;
      default:
        paymentUrl = getStorage("paymentURL") + "/payments/gateways/initialisation";
    }
  
    // Create a new form and submit the payment
    var newForm = document.createElement("form");
    newForm.method = "POST";
    newForm.action = paymentUrl;
  
    var pastInput = document.createElement("input");
    pastInput.type = "hidden";
    pastInput.name = "order_am";
    pastInput.value = RechargeAm;
  
    var authInput = document.createElement("input");
    authInput.type = "hidden";
    authInput.name = "order_userid";
    authInput.value = UserId;
  
    newForm.appendChild(pastInput);
    newForm.appendChild(authInput);
  
    document.body.appendChild(newForm);
    newForm.submit();
  
    // Show success toast after valid form submission
    updateToastDialogState(true, 1, "Order Created Successfully!");
  };
  

  const setAllRechargeOptions = () => {
    let tempData = [];
    const recentBetArr = getStorage("rechargeoptions").split(",");

    for (let i = 0; i < recentBetArr.length; i++) {
      tempData.push(
        <div
          key={i}
          className="pd-10-15 v-center br-5 ft-sz-16 bg-l-blue"
          onClick={() => onInputValChange("rechargeamount", recentBetArr[i])}
        >
          ₹{recentBetArr[i]}
        </div>
      );
    }

    setConstants((previousState) => {
      return { ...previousState, recordList: tempData };
    });
  };

  useEffect(() => {
    if (!getStorage("uid")) {
      navigate("/login", { replace: true });
    } else {
      setAllRechargeOptions();
    }
  }, []);

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <LoadingDialog intentData={pageConst} />
        <ToastDialog
          intentData={toastDialogConst}
          updateState={updateToastDialogState}
        />
        <div className="zoomAnimView">
          <div className="col-view">
            <div
              className={[styles.walletDetailView, general.bgCustomColor1].join(
                " "
              )}
            >
              <span className="cl-white ft-sz-23">Wallet</span>
              <img
                src={require("../icons/wallet_grey_icon.png")}
                className="h-w-40 mg-t-10"
                alt="icon"
              />

              <span className="cl-white ft-sz-28">
                ₹{Number(getStorage("balance")).toLocaleString()}
              </span>
              <span className="cl-white ft-sz-12">Total Balance</span>
            </div>

            <div className="w-100 pd-0-15 mg-t--40">
              <div className="w-100 col-view pd-10-15 br-10 bg-white bx-shadow-2">
                <div className="col-view">
                  <span className="ft-sz-16 ft-wgt-500 cl-l-grey">Select Payment Channel</span>

                  <div className="AppRecharge">
      {items.map((item) => (
        <div
          key={item.id}
          className={`item ${activeItem === item.id ? 'action' : ''}`}
          onClick={() => {
            setSelectedItem(item.id); // Set the selected item when clicked
            handleOnClickChannel(item); // Optionally call rechargeNow here if needed
          }} 
        >
          <img
            width="60px"
            height="19px"
            src={item.img}
            className="img"
          />
          <div className="name">{item.name}</div>
        </div>
      ))}
    </div>
    <span className="ft-sz-16 ft-wgt-500 cl-l-grey">Amount</span>
                  <div className="row-view br-b-grey">
                    <span className="cl-l-grey ft-sz-20">Rs</span>
                    <input
                      type="number"
                      className="cutm-inp ft-sz-38 h-60-p inp-ph-l-color"
                      value={pageConst.inRechargeAmnt}
                      placeholder={`${getStorage("minrecharge")} ~ 70000`}
                      onChange={(e) =>
                        onInputValChange("rechargeamount", e.target.value)
                      }
                    ></input>
                  </div>
                </div>

                <div className="w-100 g-v-3 mg-t-25">
                  {pageConst.recordList}
                </div>

                <div
                  className={[
                    general.height45px,
                    general.width100,
                    general.viewCenter,
                    general.colorWhite,
                    general.fontS20Px,
                    general.mgT10px,
                    general.borderRadius,
                    general.bgCustomColor2,
                  ].join(" ")}
                  onClick={() => {
                    const itemToRecharge = items.find(item => item.id === selectedItem); // Find the selected item
                    if (itemToRecharge) {
                      rechargeNow(itemToRecharge); // Call rechargeNow with the selected item
                      // Any additional logic after the deposit can go here
                    } else {
                      console.error("No item selected for deposit."); // Log if no item was selected
                    }
                  }}
                >
                  Deposit
                </div>

                <div className="row-view sb-view a-flex-start mg-t-20">
                  <Link
                    className="txt-deco-n col-view br-10 bg-white"
                    to={"/withdraw"}
                  >
                    <div className="txt-a-center">
                      <img
                        src={require("../media/icons/withdrawl_icon.png")}
                        className="h-w-28"
                        alt="icon"
                      />
                    </div>
                    <span className="cl-drk-black ft-sz-12 mg-t-5">
                      Withdraw
                    </span>
                  </Link>

                  <Link
                    className="txt-deco-n col-view br-10 bg-white"
                    to={"/rechargerecords"}
                  >
                    <div className="txt-a-center">
                      <img
                        src={require("../media/icons/deposit_history_icon.png")}
                        className="h-w-28"
                        alt="icon"
                      />
                    </div>
                    <span className="txt-a-center cl-drk-black ft-sz-12 mg-t-5">
                      Deposit<br></br>History
                    </span>
                  </Link>

                  <Link
                    className="txt-deco-n col-view br-10 bg-white"
                    to={"/withdrawrecords"}
                  >
                    <div className="txt-a-center">
                      <img
                        src={require("../media/icons/withdrawl_history_icon.png")}
                        className="h-w-28"
                        alt="icon"
                      />
                    </div>
                    <span className="txt-a-center cl-drk-black ft-sz-12 mg-t-5">
                      Withdraw<br></br>History
                    </span>
                  </Link>

                  <Link
                    className="txt-deco-n col-view br-10 bg-white"
                    to={"/transactions"}
                  >
                    <div className="txt-a-center">
                      <img
                        src={require("../media/icons/transactions_icon.png")}
                        className="h-w-28"
                        alt="icon"
                      />
                    </div>
                    <span className="cl-drk-black ft-sz-12 mg-t-5">
                      Transactions
                    </span>
                  </Link>
                </div>
              </div>
<div className="recharge-reminder">
  <h4>Mode of Recharge Transactions</h4>
  <ol>
    <li className="recharge-reminder-text">There are 5 channels for recharge i.e WePay(FAST),RsPay,Hbopay,EKPAY AND Super Pay.</li>
    <li className="recharge-reminder-text">It is recommended to use WePay currently with instant high success rate.</li>
    <li className="recharge-reminder-text">All Gateways Are meant for medium transactions and they are automatic channels. They are safe and secured.</li>
    <li className="recharge-reminder-text">Super Pay is a manual mode of channel for large amount of recharge.They are safe and secured but it is manually verified by local banks thereby taking some times like 2-3 hr maximum.</li>
    <li className="recharge-reminder-text">Although, Automatic payments are also fast,secured and safe for both large and low transactions, it is just for the patience of You.</li>
    <li className="recharge-reminder-text">We Thank you For reading.Happy Gaming! Bigluck99</li>

  </ol>
</div>
              <div className="mg-t-13em"></div>
            </div>
          </div>
        </div>

        <BottomNavbar activeBar="recharge" />
      </div>
    </div>
  );
}

export default Deposit;
