import React, { useState, useEffect } from 'react';
import TopBar from '../other-components/TopBar';
import LoadingDialog from '../dialogs/LoadingDialog/dialog';
import ToastDialog from "../dialogs/ToastDialog/toast";
import '../../MainStyle.css';
import general from '../css/General.module.css';
import { setStorage, getStorage, deleteStorage } from "../modals/Storage";
import { useNavigate } from 'react-router-dom';
const AllGamesList = () => {
  const [response, setResponse] = useState(null);
  const [tabActive, setTabActive] = useState('SLOTS');
  const [imageList, setImageList] = useState({});
  const [pageConst, setPageConst] = useState({
    pageTitle: 'All Games List',
    isLoadingShow: false,
    recordList: [],
  });
  const [showLoading, setShowLoading] = useState(false);


  const [toastDialogConst, setToastDialogConst] = useState({
    toastDialogShow: false,
    toastDialogMode: 2,
    toastMessage: "",
  });

  const updateToastDialogState = (data, mode, msg) => {
    // mode: 1 / success
    // mode: 2 / warning

    setToastDialogConst((previousState) => {
      return { ...previousState, toastDialogShow: data };
    });

    setToastDialogConst((previousState) => {
      return { ...previousState, toastDialogMode: mode };
    });

    setToastDialogConst((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };

  useEffect(() => {
    // Initialize image lists for each tab
    setImageList({
      SLOTS: [
        { id: 1, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/xGuraajrU9KTqWcDg1kT3g2mGije4QXM7hHKC6nQ.png' },
        { id: 2, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/zG3yxYNJvQTLJpMNbvHZawLbHIzIzXmStAYJZlbz.png' },
        { id: 30, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/cBnpBRaY11rdZjsBk0r5xnYF30smtkn7LwggSNSx.png' },
        { id: 31, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/zsD94pEK7R7XJQgPzcjECGgpnJ0D80LudxkmlqKy.png' },
        { id: 32, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/HATQOczYHAaTBMTevThuGPBJmGQTLoNpwKYCGJee.png' },
        { id: 33, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/5NRgrLmjiuySjpmvq5zxMli4WQph6nhCGwCSzCka.png' },
        { id: 34, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/ev52lnWrF6soryGTnt5qafnuPOdHzhDjXoPlTPqf.png' },
        { id: 35, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/3flwlSPsPtpqvPxZh2MKZuUNhGFvNzYO8YPucdi0.png' },
        { id: 36, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/N0ObtVvimsYAGsfVkhpnDiKjw9MyTd8rH4CpWuCj.png' },
        { id: 37, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/c5VgTbR3axH1Xv9noEJdJygBF8yn8dL2pGZ2f76w.png' },
        { id: 38, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/iVleim1PQh0ru4zigjdFheWL4okAc8869Nt3unkK.png' },
        { id: 39, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/AumHgFmKxZ6LpHgzvK5gqbLZyvgeryiYiGp3uigq.png' },
        { id: 40, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/PkNv3ZL7DNMRewU8nzMHGHjJoRihaopS5KSMliiD.png' },
        { id: 41, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/0NMm5znugdsgOagdTNGinTDLOCWmBljNVyKBuccK.png' },
        { id: 42, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/LGmOw0w4iklkLKXz81LfNjNFjDQw5IlZgRSULqy2.png' },
        { id: 43, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/VZRGHowsPgKVO4Wx1Cg1zaK6UBmN1MVjMHzBuHTA.png' },
        { id: 44, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/sGwnH7w0dmV98sYjXkGjH20zhziGYkiWqD3pvICz.png' },
      ],
      CRASH: [
        { id: 4, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/8nQsbKAVvDLXd1e3r66LYWuvXBxmIe0GqgAJK9Hq.png' },
        { id: 5, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/l2wF3J77OstjG7swSWxDj6Dt1LA2qSPMwGdy52Ts.png' },
        { id: 6, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/ggJpbsupiHgKSU9tjYcUZZVMkVE9JJs0J7RXK7tN.png' },
        { id: 26, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/Eyjfgo5I45y09wGjrscRi1Dqpv58DYdi8GMTSpEy.png' },

      ],
      MINI: [
        { id: 7, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/x4eZ5K4sTx6GzI7BLkg960h7RRq3gNjrO67eRvcy.png' },
        { id: 8, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/UkIkTcp8lU4UZuGKGrLTwke9j2xdYEzk8hYHr9hS.png' },
        { id: 9, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/BOtxz4YwxZHpbLfMgoCvrFibNa38XTJXbJQnbgdz.png' },
        { id: 92, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/yvhe59l8MYfzyXOYF8Nqkb4lJcxqwgxbZ6l1LBAf.png' },
        { id: 91, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/bVg82SVHgWMpKl3W8gW6irFmhHpnN2WOc3ThZV3k.png' },
      ],
      ROULETTE: [
        { id: 10, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/bVg82SVHgWMpKl3W8gW6irFmhHpnN2WOc3ThZV3k.png' },
      ],
      CASINO: [
        { id: 13, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/NVJ4ZZKj5qmLinIQYKX194xxCaufYFGirTOxAEKZ.png' },
        { id: 14, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/HgWx6MbSdLY4SmTEcMzYPU1bJHiuow1ZOuARCehN.png' },
        { id: 15, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/nZqvPRi41lwkbgKCEtv6SgeI8cJPr7aeffAJ7xB7.png' },
        { id: 16, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/V8PJFvojgsHM5RQZSXOjku5S7evIlPaeh3ZtSYKt.png' },
        { id: 17, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/uKU7o6jFKOu7JaXAtgH1kXtAp0Pkpg2cXTDK0OW0.png' },
        { id: 18, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/PSW6euZpSINykaR8oFyOdIZPdZlkowDAPwRRogY4.png' },
        { id: 16, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/nPx8kZFKWvD4ZLSQKW4PzeyAgL0KsO5oLbaumACM.png' },
        { id: 17, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/zTnjK2ymD9j3VKVJTHd4urstqG1FhMhtPYdOZDD1.png' },
        { id: 18, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/xMuyUS9shoFDKa2Cmf7OVS74VLQkHCzZBmBFCEQg.png' },
        { id: 16, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/2oGhGWYdFohWUbGcGdegLGWPmNenLlCl7ARh06eP.png' },
        { id: 17, url: 'https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/004e7zdOZsF9GMIhgFyGQ8AKb4AHjXz0HSTEdUb6.png' },
      ],
      LOTTERY: [
        { id: 16, url: 'image10.jpg' },
        { id: 17, url: 'image11.jpg' },
        { id: 18, url: 'image12.jpg' },
      ],
      TRENDING: [
        { id: 19, url: 'image10.jpg' },
        { id: 20, url: 'image11.jpg' },
        { id: 21, url: 'image12.jpg' },
      ],
    });
  }, []);

  const updateTabActive = (tab) => {
    setTabActive(tab);
  };

  const navigate = useNavigate();

    const handleImageClick = async (gameId) => {
        var userId = getStorage("uid");
        updateToastDialogState(true, 2, "Data Created Successfully! \n Redirecting You!");
        try {
            const response = await fetch('https://api.bigluck99.in/JiliGame/enter3rdParty.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    gameid: gameId,
                    userid: userId,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            if (data.error) {
                console.error('Error:', data.error);
                // Display an error message to the user
                alert(`Error: ${data.error}`);
            } else if (data.login_url) {
                // Navigate to the JiliGamesController route with the login URL
                navigate(`/jili-games?loginUrl=${encodeURIComponent(data.login_url)}`);
            } else {
              updateToastDialogState(true, 2, "505 Error \n Format Invalid!");

            }
        } catch (error) {
          updateToastDialogState(true, 2, "505 \n Invalid Response!");
        }
    };


  const topBarClickAction = () => {
  };

  return (
    <div className={`${general.viewCenter} ${general.appBackground}`}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(' ')}
      >
        <TopBar
          intentData={pageConst}
          multiBtn={true}
          multiBtn1=""
          multiBtn2=""
          updateState={topBarClickAction}
        />
        <LoadingDialog intentData={showLoading} />
        <ToastDialog
          intentData={toastDialogConst}
          updateState={updateToastDialogState}
        />
        <div className="game-manage-view col-view mg-t-45">
          <div className="col-view br-right-t br-left-t mg-b-15">
            <div className="col-view min-h">
              <div className="row-view ovf-scrl-x bg-drk-yellow cl-l-grey hide-sb pd-0-10">
                <span
                  className={`pd-10 mg-l-20 ${tabActive === 'SLOTS' ? 'tab-h-active' : ''}`}
                  onClick={() => updateTabActive('SLOTS')}
                >
                  Slots
                  </span>
                <span
                  className={`pd-10 mg-l-20 ${tabActive === 'CRASH' ? 'tab-h-active' : ''}`}
                  onClick={() => updateTabActive('CRASH')}
                >
                  Crash
                </span>
                <span
                  className={`pd-10 mg-l-20 ${tabActive === 'MINI' ? 'tab-h-active' : ''}`}
                  onClick={() => updateTabActive('MINI')}
                >
                  Mini
                </span>
                <span
                  className={`pd-10 mg-l-20 ${tabActive === 'ROULETTE' ? 'tab-h-active' : ''}`}
                  onClick={() => updateTabActive('ROULETTE')}
                >
                  Roulette
                </span>
                <span
                  className={`pd-10 mg-l-20 ${tabActive === 'CASINO' ? 'tab-h-active' : ''}`}
                  onClick={() => updateTabActive('CASINO')}
                >
                  Casino
                </span>
                <span
                  className={`pd-10 mg-l-20 ${tabActive === 'LOTTERY' ? 'tab-h-active' : ''}`}
                  onClick={() => updateTabActive('LOTTERY')}
                >
                  Lottery
                </span>
                <span
                  className={`pd-10 mg-l-20 ${tabActive === 'TRENDING' ? 'tab-h-active' : ''}`}
                  onClick={() => updateTabActive('TRENDING')}
                >
                  Trending
                </span>
                {/* Add more tabs here */}
              </div>
              <div className="col-view pd-18 AllGamesList">
                <div className={general.AllGamesList}>
                  {imageList[tabActive] && imageList[tabActive].map((image) => (
                    <div
                      key={image.id}
                      className="col-view mg-r-10 mg-b-10"
                      style={{ width: 'calc(20% - 10px)' }}
                    >
                      <img
                        src={image.url}
                        alt={image.id}
                        className={[
                          general.imggames,
                        ].join(' ')} 
                        onClick={() => handleImageClick(image.id)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllGamesList;