import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog/toast";
import BottomNavbar from "../other-components/BottomNavbar/Navbar";
import { copyText } from "../modals/Constants";
import { getStorage, deleteStorage } from "../modals/Storage";

import styles from "../css/Screens.module.css";
import general from "../css/General.module.css";

function Activity() {
  const [pageConst, setGameData] = useState({
    pageTitle: "Activity",
    toastDialogShow: false,
    toastMessage: "",
  });

  const navigate = useNavigate();
  const signOutAccount = () => {
    if (deleteStorage()) {
      navigate("/login", { replace: true });
    }
  };

const updateToastDialogState = (data, msg, mode = 2) => {
  setGameData((previousState) => {
    return {
      ...previousState,
      toastDialogShow: data,
      toastMessage: msg,
      toastDialogMode: mode,
    };
  });
};


  const copyMyId = () => {
    copyText(getStorage("uid"));
    updateToastDialogState(true, "ID Copied!");
  };

  useEffect(() => {
    if (!getStorage("uid")) {
      signOutAccount();
    }
  }, []);

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <ToastDialog
          intentData={pageConst}
          updateState={updateToastDialogState}
        />

        <div className={[general.posRelative].join(" ")}>
          <div
            className={[
              general.colView,
              general.alignCenter,
              general.pd10px15px,
              general.bgCustomColor1,
            ].join(" ")}
          >
            <div className="col-view">
              <p className="ft-sz-25 cl-white">Activity</p>
              <p className="ft-sz-14 cl-white">
                Please remember to follow the event page.
              </p>
              <p className="ft-sz-14 cl-white">
                We will launch user feedback activities from time to time.
              </p>
            </div>
          </div>

          <div
            className={[general.colView, styles.activityContentsView].join(" ")}
          >
            <div
              className={[
                styles.activityOptionView,
                general.width100,
                general.rowView,
                general.spaceBetween,
              ].join(" ")}
            >
</div>

<div
              className={[
                styles.activityListView,
                general.bgCustomColor4,
                general.mgT15px,
              ].join(" ")}
            >
              <img
                src="https://i.imghippo.com/files/SUSEn1723695693.jpg"
                alt="image"
              />

              <span> Welcome To Bigluck99!We Hope For the better gaming experiences!  </span>
            </div>
            
            <div
              className={[
                styles.activityListView,
                general.bgCustomColor4,
                general.mgT15px,
              ].join(" ")}
            >
              <img
                src="https://i.imghippo.com/files/hClL41723695285.jpg"
                alt="image"
              />

              <span> Promotion events ahead! Work Hard Get Rewarded Big!  </span>
            </div>

            <div
              className={[
                styles.activityListView,
                general.bgCustomColor4,
                general.mgT10px,
              ].join(" ")}
            >
              <img
                src="https://i.imghippo.com/files/lq28J1723695501.jpg"
                alt="image"
              />

              <span> Get Daily Random Bonus Upto 9999 Rs! Be Active!</span>
            </div>

            <div
              className={[
                styles.activityListView,
                general.bgCustomColor4,
                general.mgT10px,
              ].join(" ")}
            >
              <img
                src="https://i.imghippo.com/files/O30vw1723695598.jpg"
                alt="image"
              />

              <span> First Deposit Bonus! Higher the first recharge,higher the rewards! </span>
            </div>
          </div>
        </div>

        <BottomNavbar activeBar="activity" />
      </div>
    </div>
  );
}

export default Activity;