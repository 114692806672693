import React, { useEffect, useState } from "react";
import "../../MainStyle.css";
import { getStorage, setStorage } from "../modals/Storage";
import { API_ACCESS_URL } from "../modals/Constants";

const BettingDialog = ({
  gameCode,
  dialogData,
  updateState,
  toastUpdate,
  recentBetDetails,
}) => {
  const [dialogConstants, setDialogData] = useState({
    contractAmnt: 10,
    contracTotalAmnt: 10,
    contractNumLot: 1,
    isLoadingShow: false,
  });

  const updateContractAmount = (amount) => {
    setDialogData((previousState) => {
      return {
        ...previousState,
        contractAmnt: amount,
      };
    });

    updateTotalContractAmnt(amount, dialogConstants.contractNumLot);
  };

  const updateContractData = (amount, lots) => {
    updateTotalContractAmnt(amount, lots);
  };

  const updateTotalContractAmnt = (lotSize, contractAmnt) => {
    let updatedContractAmount = lotSize * contractAmnt;

    setDialogData((previousState) => {
      return { ...previousState, contracTotalAmnt: updatedContractAmount };
    });
  };

  const updateLotOnInputChange = (data) => {
    let updatedData = Number(data);

    if (updatedData >= 0 && updatedData < 1000) {
      console.log(updatedData);

      updateTotalContractAmnt(updatedData, dialogConstants.contractAmnt);

      setDialogData((previousState) => {
        return { ...previousState, contractNumLot: updatedData };
      });
    }
  };

  const updateLotData = (data) => {
    let updatedData = dialogConstants.contractNumLot + Number(data);

    if (updatedData >= 1 && updatedData < 200) {
      updateTotalContractAmnt(updatedData, dialogConstants.contractAmnt);

      setDialogData((previousState) => {
        return { ...previousState, contractNumLot: updatedData };
      });
    }
  };

  const resetDialogData = (e) => {
    if (
      e != null &&
      e.target.className ==
        "ps-fx h-100vh res-wth z-i--100 bg-l-black bt-dlg activeDialog"
    ) {
      setDialogData((previousState) => {
        return { ...previousState, contractAmnt: 10 };
      });

      setDialogData((previousState) => {
        return { ...previousState, contracTotalAmnt: 10 };
      });

      setDialogData((previousState) => {
        return { ...previousState, contractNumLot: 1 };
      });

      setDialogData((previousState) => {
        return { ...previousState, isLoadingShow: false };
      });
    }
  };

  const confirmBet = (gameCode, dialogData) => {
    setDialogData((previousState) => {
      return { ...previousState, isLoadingShow: true };
    });

    async function requestAPI(url, formData) {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Route: dialogData.gameBetRoutePath,
            AuthToken: getStorage("secret"),
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();

        updateState(null, "dismiss", "");

        if (data.status_code == "success") {
          setStorage("balance", data.account_balance, 30);
          toastUpdate(true, "Bet Success",1);
          recentBetDetails(dialogConstants.contracTotalAmnt);
        } else if (data.status_code == "balance_error") {
          toastUpdate(
            true,
            "Your balance is insufficient ! Please recharge now !"
          );
        } else if (data.status_code == "account_error") {
          toastUpdate(true, "Account Problem ! Please try again!");
        } else if (data.status_code == "betting_timeout_error") {
          toastUpdate(true, "Oops! Betting timeout! Please try again!");
        } else if (data.status_code == "auth_error") {
          toastUpdate(true, "Authentication Error! Please login again!");
        } else {
          toastUpdate(true, "There was a technical issue! Please try again!");
        }

        setDialogData((previousState) => {
          return { ...previousState, isLoadingShow: false };
        });
      } catch (error) {
        updateState(null, "dismiss", "");
        toastUpdate(true, "There was a technical issue! Please try again!");
      }
    }

    const formData = {
      USER_ID: getStorage("uid"),
      BET_ON: dialogData.gameSelectedBet,
      NUM_LOT: dialogConstants.contractNumLot,
      CONTRACT_AMOUNT: dialogConstants.contractAmnt,
      PROJECT_NAME: gameCode,
    };

    requestAPI(API_ACCESS_URL, formData);
  };

  useEffect(() => {
    setDialogData((previousState) => {
      return {
        ...previousState,
        contractNumLot: Number(dialogData.gameSelectedMultiply),
      };
    });

    updateContractData(
      dialogConstants.contractAmnt,
      Number(dialogData.gameSelectedMultiply)
    );
  }, [dialogData.gameSelectedMultiply]);

  return (
    <div
      className={`ps-fx h-100vh res-wth z-i--100 bg-l-black bt-dlg ${
        dialogData.bettingDialogShow == true ? "activeDialog" : ""
      }`}
      onClick={(e) => {
        updateState(e, false, "", "");
        resetDialogData(e);
      }}
    >
      <div
        className={`dlg-c ps-fx ps-btm z-i-1000 res-wth br-t-rl-20 bg-white ${dialogData.bettingDialogTheme}`}
      >
        <p className="dlg-thm-txt ft-sz-23 w-100 pd-10 mg-b-10 txt-a-center br-t-rl-20">
          {dialogData.bettingDialogTitle}
        </p>

        <div className="mg-t-20">
          <div className="w-100 row-view sb-view pd-10-15">
            <p className="cl-drk-black">Balance</p>

            <div className="row-view">
              <div
                className={`smpl-btn ft-sz-15 ${
                  dialogConstants.contractAmnt == "1"
                    ? "cl-white active-btn"
                    : "cl-black"
                }`}
                onClick={() => updateContractAmount("1")}
              >
                1
              </div>
              <div
                className={`smpl-btn mg-l-5 ft-sz-15 ${
                  dialogConstants.contractAmnt == "10"
                    ? "cl-white active-btn"
                    : "cl-black"
                }`}
                onClick={() => updateContractAmount("10")}
              >
                10
              </div>
              <div
                className={`smpl-btn mg-l-5 ft-sz-15 ${
                  dialogConstants.contractAmnt == "100"
                    ? "cl-white active-btn"
                    : "cl-black"
                }`}
                onClick={() => updateContractAmount("100")}
              >
                100
              </div>
              <div
                className={`smpl-btn mg-l-5 ft-sz-15 ${
                  dialogConstants.contractAmnt == "1000"
                    ? "cl-white active-btn"
                    : "cl-black"
                }`}
                onClick={() => updateContractAmount("1000")}
              >
                1000
              </div>
            </div>
          </div>

          <div className="w-100 row-view sb-view pd-10-15">
            <p className="cl-drk-black">Quantity</p>

            <div className="row-view sb-view">
              <div className="row-view">
                <div
                  className="smpl-btn ft-sz-23 active-btn"
                  onClick={() => updateLotData("-1")}
                >
                  -
                </div>
              </div>

              <div className="w-65-p mg-l-10 mg-r-10">
                <input
                  type="number"
                  className="cutm-inp ft-sz-18 inp-ph-color-l-white pd-5-10 txt-a-center br-5 br-a-l-black"
                  value={
                    dialogConstants.contractNumLot > 0
                      ? dialogConstants.contractNumLot
                      : ""
                  }
                  autoComplete="off"
                  onChange={(e) => updateLotOnInputChange(e.target.value)}
                ></input>
              </div>

              <div className="row-view">
                <div
                  className="smpl-btn ft-sz-23 active-btn"
                  onClick={() => updateLotData("+1")}
                >
                  +
                </div>
              </div>
            </div>
          </div>

          <div className="w-100 row-view jus-content-flx-end pd-15">
            <div
              className={`smpl-btn cl-black ft-sz-14 ${
                dialogConstants.contractNumLot == 1 ? "cl-white active-btn" : ""
              }`}
              onClick={() => updateLotOnInputChange("1")}
            >
              X1
            </div>
            <div
              className={`smpl-btn cl-black mg-l-5 ft-sz-14 ${
                dialogConstants.contractNumLot == 5 ? "cl-white active-btn" : ""
              }`}
              onClick={() => updateLotOnInputChange("5")}
            >
              X5
            </div>
            <div
              className={`smpl-btn cl-black mg-l-5 ft-sz-14 ${
                dialogConstants.contractNumLot == 10
                  ? "cl-white active-btn"
                  : ""
              }`}
              onClick={() => updateLotOnInputChange("10")}
            >
              X10
            </div>
            <div
              className={`smpl-btn cl-black mg-l-5 ft-sz-14 ${
                dialogConstants.contractNumLot == 20
                  ? "cl-white active-btn"
                  : ""
              }`}
              onClick={() => updateLotOnInputChange("20")}
            >
              X20
            </div>
            <div
              className={`smpl-btn cl-black mg-l-5 ft-sz-14 ${
                dialogConstants.contractNumLot == 50
                  ? "cl-white active-btn"
                  : ""
              }`}
              onClick={() => updateLotOnInputChange("50")}
            >
              X50
            </div>
          </div>

          <p className="w-100 pd-10-15">
            Total contract money is {dialogConstants.contracTotalAmnt}
          </p>
          <div className="w-100 row-view mg-t-10">
            <div
              className="h-50-p w-30 ft-sz-20 cl-white v-center mg-t-15 bg-black"
              onClick={(e) => {
                updateState(e, "dismiss", "", "");
                resetDialogData(e);
              }}
            >
              <span>Cancel</span>
            </div>

            <div
              className="h-50-p w-70 ft-sz-20 cl-white v-center mg-t-15 active-btn"
              onClick={() => confirmBet(gameCode, dialogData)}
            >
              <div
                className={`h-20-p w-20-p lodr-v ${
                  dialogConstants.isLoadingShow == false ? "hide-v" : ""
                }`}
              ></div>
              <span
                className={`${
                  dialogConstants.isLoadingShow == true ? "hide-v" : ""
                }`}
              >
                Continue
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BettingDialog;
