import React from 'react';
import '../../MainStyle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';

const noticeDialog = ({ intentData, updateState }) => {
  const handleTelegramClick = () => {
    window.location.href = 'https://telegram.dog/bigluck99_in';
  };

  return (
    <div
      className={`ps-fx h-100vh res-wth z-i--100 v-center bg-l-black ${
        intentData.noticeDialogShow ? 'activeDialog' : ''
      }`}
      onClick={() => updateState(false)}
    >
      <div className='tst-content-2'>
        <div>
          <div className='w-100 v-center pd-10 ft-sz-18 cl-white br-t-rl-5 bg-red-grad-8'>
            Notification
          </div>
          <div className='pd-15 ft-sz-18'>{intentData.noticeTitle}</div>
          <div className='pd-15 ft-sz-14'>{intentData.noticeNote}</div>
        </div>

        <div>
          <div className='br-a-l-black'></div>

          <div className='pd-15'>
            <div
              className='cl-white br-20 bg-blue telegram-button'
              onClick={handleTelegramClick}
              style={{ cursor: 'pointer' }}
            >
              <FontAwesomeIcon icon={faTelegram} size='1x' />
              <span className='ml-10'>Join Telegram</span>
            </div>
            <div className='w-100 v-center pd-5-10 ft-sz-18 cl-white br-20 bg-red-grad-8'>
              Confirm
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default noticeDialog;
