import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../other-components/TopBar";

import LoadingDialog from "../dialogs/LoadingDialog/dialog";
import "../../MainStyle.css";
import { API_ACCESS_URL } from "../modals/Constants";
import { getStorage } from "../modals/Storage";

import styles from "../css/Screens.module.css";
import general from "../css/General.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';

function Notification() {
  const navigate = useNavigate();
  const [showZoomAnimation, setZoomAnimation] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
 
  const [pageConst, setConstants] = useState({
    pageTitle: "Announcement",
    recordList: [
      {
        id: 1,
        heading: "Protect Your Bigluck99 Account: Beware of Scammers!",
        body: `
          To avoid falling victim to scams, please remember:
          <ul>
            <li>Bigluck99 will never ask you to share your login credentials or personal information via email, Telegram, or social media.</li>
            <li>Be cautious of suspicious links or attachments from unknown sources.</li>
            <li>Verify the authenticity of our official channels and websites before interacting with them.</li>
            <li>Never give out your account information or send in-game assets to anyone, even if they claim to be from Bigluck99 support.</li>
          </ul>
        `,
        timestamp: "2023-02-20 14:30:00",
      },
      {
        id: 2,
        heading: "Refer & Earn: Earn Big with Our Community!",
        body: `
          Earn money by referring friends and family to our platform! For each successful referral, you'll earn a reward. Plus, create a team on our Telegram channel or Social Medias and earn even more rewards as your team grows!
      
          Join our Telegram channel now and contact Your Agent and start building your team: <a href="https://t.me/bigluck99_in" target="_blank">Telegram Channel.</a>
          
          Contact Customer Support to get your specific agent who would assists you via online.

          Some of our popular refer and earn programs include:
          <br>
          • Recharge: Each Time Your Team Recharges, The More Reward You Get.
          <br>
          • Rebate: The Rebate Percentage Would Increase As You Refer More.
          <br>
          • Level: The more subordinates, the more rewards would be.
          <br>
          • Salary: Company Pays You Weekly Or Monthly Big Salary For Your Work.
          <br>
          • GiftCode: Agent Distribute GiftCodes For Your Teams. Terms & Conditions Apply.
          <br>
          • Additional Bonus: Company Pays Additional Bonus. and much more
          <br>
          Start referring and earning today!
        `,
        timestamp: "2023-02-19 10:45:00",
      },
      {
        id: 3,
        heading: "Handling Deposit and Withdrawal Issues during Bank Server Downtime or Maintenance",
        body: `
          During bank server downtime or maintenance, deposit and withdrawal transactions may be affected. To minimize the impact on our users, we have implemented the following measures:
          <ul>
            <li>If a deposit is initiated during bank server downtime, the transaction will be queued and processed once the servers are back online.</li>
            <li>We Continue To Update Your Pending Transactions Every 5 Minutes.</li>
            Withdrawal Issues:
            <li>If a withdrawal is initiated during bank server downtime, the transaction will be cancelled and the user will be notified.</li>
            <li>Users will be advised to try again once the bank servers are back online and do again as normal.</li>
          </ul>
         <br>
          The Transcations Are Fully Safe. In such conditions what you need is patience only.
        `,
        timestamp: "2023-02-18 16:10:00",
      },
      {
        id: 4,
        heading: "Recharge Channels & Withdrawals",
        body: `
         <p>
        We offer a variety of recharge channels to ensure a safe and convenient gaming experience. Our multiple recharge channels provide an additional layer of security and flexibility, ensuring that you can always top up your account and enjoy uninterrupted gaming.
      </p>
      <p>
        Our recharge channels include EKpay, WePay, WowPay, and many more. We continuously monitor and add new gateways to our system to provide a seamless and reliable experience for our users.
      </p>
              <br>
          Once Withdrawals Request Sent! It is sent for approval and checks and then it is sent to banks to process your payment. Approval takes like 4-5 min only and they are automatic. So, It is very fast withdraws lest in down-time.
        `,
        timestamp: "2023-02-18 16:10:00",
      },
    ],
  });

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <LoadingDialog intentData={showLoading} />

      <TopBar
        intentData={pageConst}
        multiBtn={true}
        multiBtn1=""
        multiBtn2=""
      />

      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <div
          className={[
            general.colView,
            general.pd5px15px,
            general.mgT50px,
            general.mgB15px,
          ].join(" ")}
        >
          {pageConst.recordList.map((record, index) => (
            <div key={index} className={general.notificationCard}>
              <h4 className={general.notificationHeading}>
                <FontAwesomeIcon icon={faBullhorn} className={general.notificationIcon} />
                {record.heading}
              </h4>
              <p className={general.notificationBody} dangerouslySetInnerHTML={{ __html: record.body }} />
              <p className={general.notificationTimestamp}>
                {record.timestamp}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Notification;