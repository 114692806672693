import React, { useEffect, useState } from "react";
import TopBar from "../other-components/TopBar";

import LoadingDialog from "../dialogs/LoadingDialog/dialog";
import "../../MainStyle.css";
import { API_ACCESS_URL } from "../modals/Constants";
import { getStorage } from "../modals/Storage";
import styles from "../css/Screens.module.css";
import general from "../css/General.module.css";

function GameRecords() {
  const [showLoading, setShowLoading] = useState(false);

  const [pageConst, setConstants] = useState({
    pageTitle: "My Orders",
    isLoadingShow: false,
    tabActive: "WINGO1MIN",
    recordList: [],
  });

  const topBarClickAction = (data) => {};

  const updateTabActive = (data) => {
    getGameRecords(data);

    setConstants((previousState) => {
      return { ...previousState, tabActive: data };
    });
  };

  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  };

  const updateGameRecords = (data) => {
    let tempData = [];

    for (let i = 0; i < data.length; i++) {
      tempData.push(
        <div
          key={i}
          className="pr-v w-100 br-5 mg-t-15 pd-10 bg-extm-l-white br-a-l-blue"
        >
          <div className="row-view sb-view ft-sz-15">
            <span>{data[i]["r_match_id"]}</span>
            <span>{data[i]["r_date"] + " " + data[i]["r_time"]}</span>
          </div>

          <div className="row-view sb-view w-100 ft-sz-14 mg-t-20">
            <div className="row-view">
              <div className="col-view v-center">
                <span className="ft-sz-15 mg-t-5">Selected</span>
                <span className="ft-sz-15 mg-t-5">Red</span>
              </div>

              <div className="col-view v-center mg-l-30">
                <span className="ft-sz-15 mg-t-5">Point</span>
                <span className="ft-sz-15 mg-t-5">
                  {data[i]["r_match_amount"]}
                </span>
              </div>

              <div className="col-view v-center mg-l-30">
                <span className="ft-sz-15 mg-t-5">Result</span>
                <span className="ft-sz-15 mg-t-5">
                  {data[i]["r_match_result"]}
                </span>
              </div>
            </div>

            <div
              className={`pd-2-8 ft-sz-25 ${
                data[i]["r_match_status"] == "success"
                  ? "cl-green"
                  : data[i]["r_match_status"] == "rejected"
                  ? "cl-red"
                  : ""
              }`}
            >
              ₹{data[i]["r_match_amount"]}
            </div>
          </div>
        </div>
      );
    }

    updateLoadingStatus(false);

    setConstants((previousState) => {
      return { ...previousState, recordList: tempData };
    });
  };

  function getGameRecords(project) {
    updateLoadingStatus(true);

    const fecthApiData = async (url) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Route: "route-mygame-records",
            AuthToken: getStorage("secret"),
          },
        });

        const data = await res.json();
        updateLoadingStatus(false);
        updateGameRecords(data.data);
      } catch (error) {
        updateLoadingStatus(false);
        console.log(error);
      }
    };

    fecthApiData(
      API_ACCESS_URL + "?USER_ID=" + getStorage("uid") + "&PROJECT=" + project
    );
  }

  useEffect(() => {
    getGameRecords(pageConst.tabActive);
  }, []);

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <TopBar
          intentData={pageConst}
          multiBtn={true}
          multiBtn1=""
          multiBtn2=""
          updateState={topBarClickAction}
        />
        <LoadingDialog intentData={showLoading} />

        <div className="game-manage-view col-view mg-t-45">
          <div className="col-view br-right-t br-left-t mg-b-15">
            <div className="col-view min-h">
              <div className="row-view ovf-scrl-x bg-drk-yellow cl-l-grey hide-sb pd-0-10">
                {/* <span className={`pd-10 ${pageConst.tabActive == "FAST_PARITY" ? 'tab-h-active' : ''}`} onClick={() => updateTabActive("FAST_PARITY")}>FastParity</span> */}
                {/* <span className={`pd-10 mg-l-20 ${pageConst.tabActive == "PARITY" ? 'tab-h-active' : ''}`} onClick={() => updateTabActive("PARITY")}>Parity</span> */}
                <span
                  className={`pd-10 mg-l-20 ${
                    pageConst.tabActive == "WINGO1MIN" ? "tab-h-active" : ""
                  }`}
                  onClick={() => updateTabActive("WINGO1MIN")}
                >
                  Wingo1Min
                </span>
                <span
                  className={`pd-10 mg-l-20 ${
                    pageConst.tabActive == "WINGO3MIN" ? "tab-h-active" : ""
                  }`}
                  onClick={() => updateTabActive("WINGO3MIN")}
                >
                  Wingo3Min
                </span>
                <span
                  className={`pd-10 mg-l-20 ${
                    pageConst.tabActive == "WINGO5MIN" ? "tab-h-active" : ""
                  }`}
                  onClick={() => updateTabActive("WINGO5MIN")}
                >
                  Wingo5Min
                </span>
                {/* <span className={`pd-10 mg-l-20 ${pageConst.tabActive == "DICE" ? 'tab-h-active' : ''}`} onClick={() => updateTabActive("DICE")}>Dice</span> */}
                {/* <span className={`pd-10 mg-l-20 ${pageConst.tabActive == "ANDARBAHAR" ? 'tab-h-active' : ''}`} onClick={() => updateTabActive("ANDARBAHAR")}>AndarBahar</span> */}
                <span
                  className={`pd-10 mg-l-20 ${
                    pageConst.tabActive == "WINGO10MIN" ? "tab-h-active" : ""
                  }`}
                  onClick={() => updateTabActive("WINGO10MIN")}
                >
                  Wingo10Min
                </span>

                <span
                  className={`pd-10 mg-l-20 ${
                    pageConst.tabActive == "K3LOTTERY1MIN" ? "tab-h-active" : ""
                  }`}
                  onClick={() => updateTabActive("K3LOTTERY1MIN")}
                >
                  K3Lottery1Min
                </span>

                <span
                  className={`pd-10 mg-l-20 ${
                    pageConst.tabActive == "K3LOTTERY3MIN" ? "tab-h-active" : ""
                  }`}
                  onClick={() => updateTabActive("K3LOTTERY3MIN")}
                >
                  K3Lottery3Min
                </span>

                <span
                  className={`pd-10 mg-l-20 ${
                    pageConst.tabActive == "K3LOTTERY5MIN" ? "tab-h-active" : ""
                  }`}
                  onClick={() => updateTabActive("K3LOTTERY5MIN")}
                >
                  K3Lottery5Min
                </span>

                <span
                  className={`pd-10 mg-l-20 ${
                    pageConst.tabActive == "K3LOTTERY10MIN" ? "tab-h-active" : ""
                  }`}
                  onClick={() => updateTabActive("K3LOTTERY10MIN")}
                >
                  K3Lottery10Min
                </span>

                <span
                  className={`pd-10 mg-l-20 ${
                    pageConst.tabActive == "ANDARBAHAR" ? "tab-h-active" : ""
                  }`}
                  onClick={() => updateTabActive("ANDARBAHAR")}
                >
                  Andar Bahar
                </span>

                <span
                  className={`pd-10 mg-l-20 ${
                    pageConst.tabActive == "WHEELOCITY" ? "tab-h-active" : ""
                  }`}
                  onClick={() => updateTabActive("WHEELOCITY")}
                >
                  Wheelocity
                </span>
                {/* <span className={`pd-10 mg-l-20 ${pageConst.tabActive == "MineSweeper" ? 'tab-h-active' : ''}`} onClick={() => updateTabActive("MineSweeper")}>MineSweeper</span> */}
              </div>

              <div className="col-view pd-18">
                <div className="col-view">{pageConst.recordList}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GameRecords;
