import React from "react";
import { Link, useLocation } from "react-router-dom";
import general from "../../css/General.module.css";
import styles from "../BottomNavbar/Navbar.module.css";

const BottomNavbar = () => {
  const location = useLocation();
  const activeBar = location.pathname.slice(1); // Remove leading slash

  return (
    <div className={general.rowView}>
      <nav className={[general.respWidth, styles.navBarView].join(" ")}>
        <Link
          className={[
            styles.navItemView,
            styles.transition05,
            activeBar === "promotion" ? styles.navItemAnimate : "",
          ].join(" ")}
          to={"/promotion"}
        >
          <img
            src={require("../../media/icons/promotion_active_icon.png")}
            className={`h-w-26 ${activeBar !== "promotion" ? "hide-v" : ""}`}
            alt="icon"
          />
          <img
            src={require("../../media/icons/promotion_icon.png")}
            className={`h-w-26 ${activeBar === "promotion" ? "hide-v" : ""}`}
            alt="icon"
          />
          <p>Promotion</p>
        </Link>

        <Link
          className={[
            styles.navItemView,
            styles.transition05,
            activeBar === "activity" ? styles.navItemAnimate : "",
          ].join(" ")}
          to={"/activity"}
        >
          <img
            src={require("../../media/icons/activity_active_icon.png")}
            className={`h-w-26 ${activeBar !== "activity" ? "hide-v" : ""}`}
            alt="icon"
          />
          <img
            src={require("../../media/icons/activity_icon.png")}
            className={`h-w-26 ${activeBar === "activity" ? "hide-v" : ""}`}
            alt="icon"
          />
          <p>Activity</p>
        </Link>

        <Link
          className={[
            styles.navItemView,
            styles.transition05,
            activeBar === "games" ? styles.navItemAnimate : "",
          ].join(" ")}
          to={"/games"}
        >
          <div className={styles.navGamesItemView}>
            <div></div>
            <img
              src={require("../../media/icons/games_icon.png")}
              alt="icon"
            />
          </div>
          <p>Games</p>
        </Link>

        <Link
          className={[
            styles.navItemView,
            styles.transition05,
            activeBar === "wallet" ? styles.navItemAnimate : "",
          ].join(" ")}
          to={"/wallet"}
        >
          <img
            src={require("../../media/icons/wallet_active_icon.png")}
            className={`h-w-26 ${activeBar !== "wallet" ? "hide-v" : ""}`}
            alt="icon"
          />
          <img
            src={require("../../media/icons/wallet_icon.png")}
            className={`h-w-26 ${activeBar === "wallet" ? "hide-v" : ""}`}
            alt="icon"
          />
          <p>Wallet</p>
        </Link>

        <Link
          className={[
            styles.navItemView,
            styles.transition05,
            activeBar === "account" ? styles.navItemAnimate : "",
          ].join(" ")}
          to={"/account"}
        >
          <img
            src={require("../../media/icons/account_active_icon.png")}
            className={`h-w-26 ${activeBar !== "account" ? "hide-v" : ""}`}
            alt="icon"
          />
          <img
            src={require("../../media/icons/account_icon.png")}
            className={`h-w-26 ${activeBar === "account" ? "hide-v" : ""}`}
            alt="icon"
          />
          <p>Account</p>
        </Link>
      </nav>
    </div>
  );
};

export default BottomNavbar;
